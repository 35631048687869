const html = document.querySelector("html");

const normalFont = document.querySelector(".wcag__fonts-normal");
const largerFont = document.querySelector(".wcag__fonts-larger");
const largestFont = document.querySelector(".wcag__fonts-largest");

if (normalFont) {
  normalFont.addEventListener("click", () => {
    html.style.fontSize = "";
  });
}

if (largerFont) {
  largerFont.addEventListener("click", () => {
    html.style.fontSize = "66.5%";
  });
}

if (largestFont) {
  largestFont.addEventListener("click", () => {
    html.style.fontSize = "70%";
  });
}

const normalContrast = document.querySelector(".wcag__contrast-normal");
const highContrast = document.querySelector(".wcag__contrast-high");

if (normalContrast) {
  normalContrast.addEventListener("click", () => {
    document.body.classList.remove("high-contrast");
  });
}

if (highContrast) {
  highContrast.addEventListener("click", () => {
    document.body.classList.add("high-contrast");
  });
}
